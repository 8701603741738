.homeguideModal{
  width: 100%;
  height: calc(100vh - 29.9rem);
  
  .multicountry-box{
    height: auto;

    .multicountry-body{
      padding-bottom: 6.4rem;
    }
  }
}