
.homeguideModal{
  position: relative;
  width: calc(100vw - 40rem);
  height: 100vh;
  background-color: $blue;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .icon--close{
    display: none;
  }
}